.list {
  &-item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    &--thumbnails {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      min-width: 200px;
      flex-grow: 1;
      flex-shrink: 1;
      div {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 30%;
        min-width: 100px;
        &:nth-child(n+7) {
          display: none;
        }
      }
      a:hover {
        text-decoration: none;
      }
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      flex-grow: 1;
      flex-shrink: 1;
    }
    &--labels {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: right;
      line-height: 1;
      .label {
        margin-bottom: 0.5em;
        img {
          vertical-align: text-bottom;
        }
      }
    }
    &--grow {
      flex-grow: 1;
    }
    &--shrink {
      flex-shrink: 1;
    }
    &--no-grow {
      flex-grow: 0;
    }
    &--no-shrink {
      flex-shrink: 0;
    }
    &--container {
      position: relative;
    }
  }
  &-thumbnail {
    img,
    div {
      border: 1px solid #eee;
      border-radius: 3px;
      display: inline-block;
    }
  }
}