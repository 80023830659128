.img-error-preview,
.img-error-gal-home,
.img-error-list-home {
  text-align: center;
  padding: 14% 0;
  &:before {
    content: "\f03e";
    display: block;
    font-family: FontAwesome;
    font-size: 8em;
  }
}

.img-error-thumb {
  > div.img-error-container {
    padding-bottom: 75%;
    position: relative;
    > div {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding: 15px;
    }
  }
}

.img-error-list-home--thumbnail {
  font-size: 0.7em;
  padding: 14% 0;
  &:before {
    display: block;
    content: "\f03e";
    font-family: FontAwesome;
    font-size: 3em;
  }
}

.thumbnail {
  a:hover {
    text-decoration: none;
  }
}
