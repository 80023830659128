.clickable {
  cursor: pointer;
}

.margin {
  &-top {
    margin-top: 15px  !important;
    &--none {
      margin-top: 0  !important;
    }
  }

  &-bottom {
    margin-bottom: 15px !important;
    &--none {
      margin-bottom: 0  !important;
    }
  }
}

.modal {
  z-index: 1062;
  &-backdrop {
    z-index: 1061;
  }
}

// gritter needs to be global, since the gritter messages are rendered outside of our bidlogix container
@import "gritter.less";
