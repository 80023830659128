@node-modules-path: "../../../node_modules/";

@import "./includes/global.less";

// Core variables and mixins
.bidlogix-app {
  @import "bootstrap";
  // Import Bidlogix less files
  @import "my-variables.less";

  @import "./includes/includes.less";

  // bootstrap isolation fixes  
  .btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
    display: table;
    content: " ";
  }
  
  .btn-group-vertical>.btn-group:after, .btn-toolbar:after, .clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .form-horizontal .form-group:after, .modal-footer:after, .modal-header:after, .nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .panel-body:after, .row:after {
    clear: both;
  }

  .thumbnail > img,
  .thumbnail a > img {
    display: block;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
  }
}

// fix for modals when using namespaced Bootstrap
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// ensure buttons outside app can still be hidden etc
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.hidden {
  display: none !important;
}
