.overlay {
  &-loader {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    background: rgba(255,255,255,0.5);
    display: flex;
    align-items: center;
    &--content {
      display: flex;
      margin: 0 auto;
    }
  }
}

// ensures that modals can be closed by clicking the backdrop
#myModal {
  z-index: 1062;
  > div:not(.modal-dialog) {
    pointer-events: none;
  }
  .modal-dialog {
    pointer-events: auto;
  }
}

#itemMessageRegion .popover,
#auctionMessage.popover {
  display: block;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

#itemMessageRegion .popover{
  max-width: 80%;
  min-width: 50%;
}

#auctionMessage.popover {
  font-size: 2rem;
  max-width: 600px;
  text-align: center;
}