.grid {
  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    &--item {
      flex-basis: 320px;
      flex-grow: 0;
      max-width: 400px;
      margin: 15px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    &--labels {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: right;
      line-height: 1;
      .label {
        margin-bottom: 0.5em;
        img {
          vertical-align: text-bottom;
        }
      }
    }
  }
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    &--thumbnail {
      border-radius: 3px;
    }
    &--content {
      flex-grow: 1;
    }
    &--grow {
      flex-grow: 1;
    }
    &--shrink {
      flex-shrink: 1;
    }
    &--no-grow {
      flex-grow: 0;
    }
    &--no-shrink {
      flex-shrink: 0;
    }
  }
}