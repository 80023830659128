/* the norm */
#gritter-notice-wrapper {
	position:fixed;
	top:20px;
	right:20px;
	width:301px;
	z-index:999999;
}
#gritter-notice-wrapper.top-left {
    left: 20px;
    right: auto;
}
#gritter-notice-wrapper.bottom-right {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
}
#gritter-notice-wrapper.bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
}
.gritter-item-wrapper {
	position:relative;
	margin:0 0 10px 0;
}
.gritter-top {
	height:10px;
}
.hover .gritter-top {
	background-position:right -30px;
}
.gritter-bottom {
	height:8px;
	margin:0;
}
.hover .gritter-bottom {
	background-position: bottom right;
}
.gritter-item {
	display:block;
	padding:2px 11px 8px 11px;
	font-size: 12px;
	z-index:999999;
}
.hover .gritter-item {
	background-position:right -40px;
}
.gritter-item p {
	padding:0;
	margin:0;
	word-wrap:break-word;
}
.gritter-close {
	display:none;
	position:absolute;
	top:-6px;
	left:-6px;
	background:url(../images/gritter.png) no-repeat left top;
	cursor:pointer;
	width:30px;
	height:30px;
	text-indent:-9999em;
}
.gritter-title {
	font-size:14px;
	font-weight:bold;
	padding:0 0 7px 0;
	display:block;
}
.gritter-image {
	width:48px;
	height:48px;
	float:left;
}
.gritter-with-image,
.gritter-without-image {
	padding:0;
}
.gritter-with-image {
	width:220px;
	float:right;
}
.gritter-light .gritter-title {
    text-shadow: none;
}
